import { ElementRef, FC, Suspense, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import FormRendererV2 from '../../components/form/renderer/FormRendererV2';
import TopNavPortal from '../../components/layout/top-menu/TopNavPortal';
import StaticBreadCrumb from '../../components/shared/breadcumb/StaticBreadCrumb';
import Button, { ButtonSize, ButtonType } from '../../components/shared/form-control/Button';
import InlineEditor from '../../components/shared/form-control/InlineEditor';
import DeleteIcon from '../../components/shared/icon/DeleteIcon';
import PageLoader from '../../components/shared/page-loader/PageLoader';
import RouteGuard from '../../components/shared/RouteGuard';
import { Heading, HeadingSize } from '../../components/shared/text/Heading';
import { FormRendererDesignContext, FormRendererDesignDefaults } from '../../contexts/FormRendererDesignContext';
import { FormRendererMode, FormStepTitleProviderProps } from '../../contexts/FormRendererDesignContextTypes';
import { ToastType, useToasts } from '../../contexts/ToastContext';
import { EventSystem } from '../../events/EventSystem';
import FormAnswerEvent from '../../events/FormAnswerEvent';
import useResourcePermissions from '../../hooks/permissions/useResourcePermissions';
import { ClientForm, FormReferenceDirection } from '../../models/ClientForm';
import { BulkFormAnswers, FormListItem } from '../../models/Form';
import ClientFormService from '../../services/ClientFormService';
import FormResponseService from '../../services/FormResponseService';
import { FCWithChildren } from '../../types/FCWithChildren';
import DateUtils from '../../utils/DateUtils';
import LanguageUtils from '../../utils/LanguageUtils';
import { clientFormRoute } from '../../utils/NavigationUtils';
import { ModalContext } from '../../contexts/ModalContext';
import ConfirmationModal from '../../components/shared/modal/variants/ConfirmationModal';
import { useCurrentClient } from '../../global-state/Clients';
import { useCurrentUser } from '../../global-state/Auth';

const SectionTitleProvider: FC<FormStepTitleProviderProps> = (props) => {
  const { step } = props;
  return (
    <div className="mx-4 my-2 pt-2">
      <Heading className="font-medium" size={HeadingSize.H2} actualSize={HeadingSize.H5}>
        {LanguageUtils.getTranslation('title', step.translations)}
      </Heading>
    </div>
  );
};

const ActionWrapper: FCWithChildren = (props) => {
  const { children } = props;

  return <div className="my-6">{children}</div>;
};

const RecordEditPage: FC = () => {
  const [loading, setLoading] = useState(true);
  const [clientForm, setClientForm] = useState<ClientForm | null>(null);
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [contextTopPx, setContextTopPx] = useState(0);
  const [references, setReferences] = useState<FormListItem[][]>([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [answersToSubmit, setAnswersToSubmit] = useState<Record<string, BulkFormAnswers>>({});

  const contentRef = useRef<HTMLDivElement>(null);
  const formRendererRef = useRef<ElementRef<typeof FormRendererV2>>(null);

  const currentClient = useCurrentClient((x) => x.value);
  const currentUser = useCurrentUser((x) => x.value);
  const params = useParams<{ recordId: string; clientId: string; resourceId: string }>();
  const { canMaintainRecords } = useResourcePermissions(clientForm?.form);
  const { t } = useTranslation('resources');
  const navigate = useNavigate();
  const toasts = useToasts();
  const toastsRef = useRef(toasts);

  useEffect(
    function setupContextMaxHeight() {
      if (!contentRef.current) {
        return;
      }

      setContextTopPx(contentRef.current.offsetTop ?? 0);
    },
    [clientForm],
  );

  useEffect(
    function fetchRecord() {
      if (!params.recordId) {
        return;
      }

      ClientFormService.getForm(params.recordId).then((res) => {
        setClientForm(res.data);
        setLoading(false);
      });
    },
    [params.recordId],
  );

  useEffect(
    function fetchReferences() {
      if (!clientForm) {
        return;
      }

      Promise.all([
        ClientFormService.getReferences(clientForm.id, FormReferenceDirection.Target),
        ClientFormService.getReferences(clientForm.id, FormReferenceDirection.Source),
      ]).then((results) => {
        // Group by templateId
        const formMap: Record<string, FormListItem[]> = {};
        for (const form of [...results[0].data, ...results[1].data]) {
          if (!formMap[form.templateId]) {
            formMap[form.templateId] = [];
          }
          formMap[form.templateId].push(form);
        }

        const result = Object.values(formMap);
        result.sort((a, b) =>
          LanguageUtils.getTranslation('title', a[0].translations).localeCompare(LanguageUtils.getTranslation('title', b[0].translations)),
        );
        for (const element of result) {
          element.sort((a, b) => a.subtitle.localeCompare(b.subtitle));
        }
        setReferences(result);
      });
    },
    [clientForm],
  );

  const subtitleChange = useCallback(
    (newValue: string) => {
      if (!clientForm) {
        return;
      }

      setClientForm((prev) => prev && { ...prev, subtitle: newValue, modifiedUtc: new Date().toISOString() });
      ClientFormService.updateForm(
        clientForm.id,
        clientForm.accessType,
        null,
        null,
        newValue,
        clientForm.clientModuleId,
        clientForm.clientModuleSectionId,
      );
    },
    [clientForm],
  );

  const goToStep = useCallback((index: number) => {
    setCurrentSectionIndex(index);
    formRendererRef.current?.changeStep(index);
  }, []);

  const designContextValues = useMemo(
    () => ({
      ...FormRendererDesignDefaults,
      sectionTitleProvider: SectionTitleProvider,
      actionWrapper: ActionWrapper,
    }),
    [],
  );

  const onDeleteModalClose = useCallback(() => {
    setDeleteModalOpen(false);
  }, []);

  useEffect(function saveAnswers() {
    const handler = (evt: FormAnswerEvent) => {
      setAnswersToSubmit((prev) => ({
        ...prev,
        [evt.actionId]: {
          actionId: evt.actionId,
          data: evt.answer,
        },
      }));
    };

    EventSystem.listen('before-form-answer', handler);

    return () => EventSystem.stopListening('before-form-answer', handler);
  }, []);

  const saveAnswers = useCallback(() => {
    if (!clientForm) {
      return Promise.resolve(false);
    }

    setAnswersToSubmit({});

    return FormResponseService.submitResponseBulk({
      clientFormId: clientForm.id,
      answers: Object.values(answersToSubmit),
    })
      .then(() => {
        setClientForm((prev) => prev && { ...prev, modifiedUtc: new Date().toISOString() });
        toastsRef.current.addToast({
          title: t('record.saved.heading'),
          type: ToastType.SUCCESS,
          description: t('record.saved.subheading'),
          expiresInMs: 5000,
        });
      })
      .then(() => true)
      .catch((err) => {
        toastsRef.current.addToast({
          title: err.meta.code,
          type: ToastType.ERROR,
          description: err.meta.message,
          expiresInMs: 5000,
        });
        return false;
      });
  }, [answersToSubmit, clientForm, t]);

  const resourceUrl = useMemo(
    () => `/clients/${currentClient?.id}/resources/${clientForm?.form.id}${clientForm?.form.clientId === currentClient?.id ? '?isLocal=true' : ''}`,
    [clientForm?.form.clientId, clientForm?.form.id, currentClient?.id],
  );

  const deleteResource = useCallback(() => {
    if (!clientForm) {
      return;
    }

    ClientFormService.deleteForm(clientForm.id).then(() => {
      navigate(resourceUrl);
    });
  }, [clientForm, navigate, resourceUrl]);

  return (
    <PageLoader loading={loading}>
      {clientForm && (
        <div className="flex h-full max-h-full flex-col">
          <TopNavPortal>
            <StaticBreadCrumb
              currentStepName={clientForm.subtitle}
              breadCrumbs={[
                {
                  name: t('heading'),
                  path: `/clients/${currentClient?.id}/resources`,
                },
                {
                  name: LanguageUtils.getTranslation('title', clientForm.form.translations),
                  path: resourceUrl,
                },
              ]}
            />
          </TopNavPortal>

          <div className="flex min-w-full max-w-0 justify-between px-4 py-2">
            <div className="flex-grow truncate">
              <InlineEditor value={clientForm.subtitle} onChange={subtitleChange} size="text-dpm-32" maxLength={100} disabled={!canMaintainRecords} />
              <span className="text-dpm-16 text-gray-2">{LanguageUtils.getTranslation('title', clientForm.form.translations)}</span>
            </div>
            <div className="flex-shrink-0 text-right">
              {canMaintainRecords && (
                <div className="mt-2 flex gap-4">
                  <Button size={ButtonSize.S} type={ButtonType.SECONDARY} onClick={() => setDeleteModalOpen(true)}>
                    <div className="flex items-center gap-2">
                      <DeleteIcon className="h-6 w-6" /> {t('record.buttons.delete')}
                    </div>
                  </Button>
                  <Button size={ButtonSize.S} type={ButtonType.PRIMARY} onClick={saveAnswers} disabled={Object.keys(answersToSubmit).length === 0}>
                    {t('record.buttons.save')}
                  </Button>
                </div>
              )}
              <div className="text-dpm-12 text-gray-2 my-2">
                {t('record.last-modified', { datetime: DateUtils.formatDateTime(new Date(clientForm.modifiedUtc)) })}
              </div>
            </div>
          </div>

          <div
            className="border-color-on-first-primary-1 flex flex-grow border-t"
            ref={contentRef}
            style={{ maxHeight: `calc(100% - ${contextTopPx}px)` }}
          >
            <div className="border-color-on-first-primary-1 w-[250px] flex-shrink-0 select-none overflow-y-auto border-r">
              {clientForm.form.sections
                .filter((x) => !!x.visible)
                .map((step, i) => (
                  <div
                    key={step.id}
                    onClick={() => goToStep(i)}
                    className={`${
                      i === currentSectionIndex ? 'bg-accent-light-mid text-color-1' : 'hover:bg-accent-light-mid text-color-2'
                    } cursor-pointer p-4 text-center font-medium`}
                  >
                    {LanguageUtils.getTranslation('title', step.translations)}
                  </div>
                ))}
            </div>
            <div className="max-h-full flex-auto overflow-y-auto">
              <Suspense fallback={<PageLoader loading isSuspense />}>
                <FormRendererDesignContext.Provider value={designContextValues}>
                  <FormRendererV2
                    ref={formRendererRef}
                    clientForm={clientForm}
                    initialMode={FormRendererMode.EditView}
                    canEdit={canMaintainRecords}
                    moduleId={clientForm?.clientModuleId}
                    moduleSectionId={clientForm?.clientModuleSectionId}
                    featureToggles={{
                      disableAutoAnswerSubmit: true,
                    }}
                  />
                </FormRendererDesignContext.Provider>
              </Suspense>
            </div>
            <div className="border-color-on-first-primary-1 w-[350px] flex-shrink-0 overflow-y-auto border-l text-black">
              <div className="bg-accent-light-mid border-primary-2 text-color-1 border-b-2 p-4 text-center font-medium">
                {t('record.associations.heading')}
              </div>

              <div>
                {references.length === 0 && (
                  <div className="mt-32 flex flex-col items-center justify-center text-center">
                    <Heading size={HeadingSize.H5} className="font-medium">
                      {t('record.associations.empty-heading')}
                    </Heading>
                    <div>{t('record.associations.empty-description')}</div>
                  </div>
                )}
                {references.map((templateGroup) => (
                  <div key={templateGroup[0].templateId} className="my-4 border-t border-black px-2 py-4 first:mt-0 first:border-t-0">
                    <div className="text-dpm-12">{t('record.associations.activity-type')}</div>
                    <div className="font-medium">{LanguageUtils.getTranslation('title', templateGroup[0].translations)}</div>

                    <div className="text-dpm-12 mt-4">{t('record.associations.activity')}</div>
                    {templateGroup.map((clientForm) => (
                      <div key={clientForm.id} className="font-medium">
                        <Link className="hover:underline" to={clientFormRoute(clientForm, currentUser)}>
                          {clientForm.subtitle}
                        </Link>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
      <ModalContext.Provider value={{ open: deleteModalOpen, onClose: onDeleteModalClose }}>
        <ConfirmationModal
          title={t('record.delete-modal.heading')}
          description={t('record.delete-modal.subheading')}
          cancelText={t('record.delete-modal.buttons.cancel')}
          confirmText={t('record.delete-modal.buttons.delete')}
          onCancel={onDeleteModalClose}
          onConfirm={deleteResource}
          alt
        />
      </ModalContext.Provider>
      <RouteGuard when={Object.keys(answersToSubmit).length > 0} onSave={saveAnswers} />
    </PageLoader>
  );
};

export default RecordEditPage;
